import logoImg from '@app/assets/co_cdec.svg'
import { authOptions } from '@app/lib/server'
import { Button, Center, Paper, Stack, Text, useMantineTheme } from '@mantine/core'
import { GetServerSideProps, type NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import { getServerSession } from 'next-auth/next'
import { signIn } from 'next-auth/react'
import { useCallback } from 'react'

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  // using the context's req/res and next-auth config, get the current user's session (if any)
  const session = await getServerSession(req, res, authOptions)

  if (session?.user?.id) {
    // if the requested page needs a session but there isn't an authorized user,
    // redirect to login with a callback url to return to the original page
    return {
      redirect: {
        destination: '/dashboard',
        permanent: false
      }
    }
  }

  return {
    props: {}
  }
}

const LoginPage: NextPage = () => {
  const theme = useMantineTheme()

  return (
    <Center
      bg={theme.colors.navy[6]}
      h='100vh'
    >
      <Head>
        <title>CODEC / Sign In</title>
      </Head>
      <Paper
        p='xl'
        radius='xl'
      >
        <Stack
          align='center'
          spacing='xl'
        >
          <Image
            alt='Logo'
            height={100}
            src={logoImg}
            priority
          />
          <Text
            fw='bold'
            size='xl'
          >
            MetrixEnroll for Universal Preschool
          </Text>
          <Button
            fullWidth
            onClick={useCallback(() => signIn('cognito'), [])}
            radius='xl'
            size='lg'
          >
            Login with MetrixAuth
          </Button>
        </Stack>
      </Paper>
    </Center>
  )
}

export default LoginPage
